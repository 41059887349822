import React, { useEffect, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { useMedia } from 'use-media';

import { Tween, SplitWords } from 'react-gsap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/all';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobileSafari } from 'react-device-detect';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Header from '../../components/header';
import Footer from '../../components/footer';
import SelectLang from '../../components/selectLang';
import ClientOnly from '../../components/clientOnly';
import Cursor from '../../components/cursor';
import Video from '../../components/video';

import { Section, Div, H1, P, Span, H3, NavLink } from '../../components/styles';
import video1 from '../../../static/video/video1.mp4';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin);

const ForReaders = () => {
  const Intl = useIntl();
  const isTablet = useMedia({ minWidth: '768px' });
  const introHeadingRef = useRef();

  useEffect(() => {
    const { body } = document;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    document.querySelector('body').classList.remove('prevent-scroll');
  }, []);

  return (
    <Layout>
      <SEO
        title={Intl.formatMessage({ id: 'title' })}
        desc={Intl.formatMessage({ id: 'description' })}
        keywords={Intl.formatMessage({ id: 'keywords' })}
      />
      <Helmet>
        <script>
          {`var _paq = window._paq = window._paq || [];

          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//ec2-18-197-155-62.eu-central-1.compute.amazonaws.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
      </Helmet>
      <main>
        <ClientOnly>
          {isTablet && (
            <Cursor color="0, 255, 36" outerAlpha={1} innerSize={6} outerSize={16} outerScale={2} />
          )}
        </ClientOnly>
        <Header />
        <Section id="lang-nav" bg="var(--section-bg-color)">
          <Div className="container" display="grid" maxWidth="1440px" mx="auto">
            <Div
              display="grid"
              gridColumn="1 / 4"
              alignSelf="end"
              justifyContent={['center', 'center', 'end']}
            >
              <AnimatePresence>
                <motion.div initial={{ y: -100 }} animate={{ y: 0 }} exit={{ y: -100 }}>
                  <SelectLang />
                </motion.div>
              </AnimatePresence>
            </Div>
          </Div>
        </Section>
        <Section id="intro" bg="var(--section-bg-color)">
          <Div
            className="container"
            display="grid"
            gridTemplateRows={['1fr 127px']}
            gridTemplateColumns={['1fr 1fr']}
            maxWidth="1440px"
            height="100vh"
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Div
              display="inline-flex"
              alignSelf="center"
              alignItems="center"
              justifyContent="space-between"
              gridColumn="1 / 4"
              zIndex={8}
            >
              <H1
                fontSize={['3.5rem', '3.5rem', '4rem', '5rem']} // was: 4
                lineHeight={['4rem', '4rem', '6rem']} // was: 4.1
                ref={introHeadingRef}
                className="intro-heading"
                maxWidth="900px"
              >
                <Tween
                  from={{ autoAlpha: 0, y: 100 }}
                  to={{ autoAlpha: 1, y: 0 }}
                  stagger={0.25}
                  ease="elastic.out(0.1, 0.1)"
                  delay={0.8}
                >
                  <SplitWords
                    wrapper={<Span className="word" style={{ display: 'inline-block' }} />}
                  >
                    {Intl.formatMessage({ id: 'intro.heading_readers' })}
                  </SplitWords>
                </Tween>
              </H1>
            </Div>
            <AnimatePresence>
              <Div
                display="grid"
                alignSelf="start"
                justifyContent="start"
                marginTop={isMobileSafari ? -80 : 0}
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
              >
                <Div
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={(evt) => {
                    evt.preventDefault();
                    scrollTo('#for-everyone');
                  }}
                >
                  <P fontSize={['1.2rem', '1.2rem', '1.6rem']}>
                    {Intl.formatMessage({ id: 'intro.discover' })}
                  </P>

                  <svg
                    className="arrow-down"
                    viewBox="0 0 20 27"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 23.088l-6.8-6.831c-.4-.4-1-.4-1.4 0-.198.2-.3.502-.3.702 0 .2.102.503.3.703L9.303 26.2c.398.4 1 .4 1.398 0l8.501-8.538a.975.975 0 000-1.405c-.398-.4-1-.4-1.398 0L11 23.088V1.504c0-.604-.398-1.004-1-1.004-.598 0-1 .4-1 1.004v21.584z"
                      fill="#000"
                      fillRule="nonzero"
                    />
                  </svg>
                </Div>
              </Div>
            </AnimatePresence>
          </Div>
        </Section>
        <Section id="for-everyone" bg="var(--section-bg-color)" minHeight="100vh" data-section={1}>
          <Div
            className="trigger12"
            display="grid"
            gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 400px']}
            alignItems="center"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Tween
              from={{
                autoAlpha: 0,
                y: 50,
              }}
              to={{
                scrollTrigger: {
                  trigger: '.trigger12',
                  start: 'top 50%',
                },
                autoAlpha: 1,
                y: 0,
              }}
              duration={0.75}
              stagger={0.25}
            >
              <H3
                maxWidth="687px"
                fontSize={['3rem', '3rem', '5rem']}
                lineHeight={['3.5rem', '3.5rem', '6rem']}
                m={0}
              >
                <FormattedMessage
                  id="forEveryone.section5.heading1"
                  values={{
                    br: <br />,
                  }}
                />
              </H3>
              <Div maxWidth={['80%', '50%', '50%', '80%']} mx="auto" textAlign="center">
                <Video src={video1} />
              </Div>
            </Tween>
          </Div>

          <Div
            className="trigger13"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['unset', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Tween
              from={{
                autoAlpha: 0,
                y: 100,
                scale: 0.8,
              }}
              to={{
                scrollTrigger: {
                  trigger: '.trigger13',
                  start: 'top 50%',
                },
                autoAlpha: 1,
                scale: 1,
                y: 0,
              }}
              duration={0.75}
              stagger={0.25}
            >
              <Div
                maxWidth={['288px', '288px', '288px', '296px']}
                gridArea="1 / 1"
                alignSelf={['start', 'start', 'center']}
                mr={['initial', 'initial', 'auto']}
                ml={['auto', 'auto', 'auto']}
                zIndex="5"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296 635" className="svg-one">
                  <title>1</title>
                  <path
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    d="M295.872 635V.87H164.036L.12 114.686V234.22L161.84 122.598h2.637V635z"
                  />
                </svg>
              </Div>
              <Div
                maxWidth="880px"
                textAlign={['left', 'left', 'center']}
                gridArea="1 / 1"
                zIndex="10"
              >
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  m={0}
                >
                  <FormattedMessage
                    id="forEveryone.section6.heading1"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
              </Div>
            </Tween>
          </Div>

          <Div
            className="trigger14"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['unset', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Tween
              from={{
                autoAlpha: 0,
                y: 100,
                scale: 0.8,
              }}
              to={{
                scrollTrigger: {
                  trigger: '.trigger14',
                  start: 'top 50%',
                },
                autoAlpha: 1,
                scale: 1,
                y: 0,
              }}
              duration={0.75}
              stagger={0.25}
            >
              <Div
                maxWidth={['288px', '288px', '288px', '470px']}
                gridArea="1 / 1"
                alignSelf={['start', 'start', 'center']}
                mr={['initial', 'initial', 'auto']}
                ml={['auto', 'auto', 'auto']}
                zIndex="5"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 649" className="svg-two">
                  <title>2</title>
                  <path
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    d="M469.448 649V543.531H183.364v-2.636L338.052 391.92c69.873-69.434 122.607-128.76 122.607-207.861C460.66 78.15 368.813.807 235.22.807 96.793.807.992 85.62.992 205.592v2.197h123.047v-2.197c0-58.447 45.264-101.514 106.787-101.514 58.887 0 100.635 37.793 100.635 85.254 0 43.945-19.775 76.025-83.936 140.186L8.462 559.79V649h460.986z"
                  />
                </svg>
              </Div>
              <Div
                maxWidth="880px"
                textAlign={['left', 'left', 'center']}
                gridArea="1 / 1"
                zIndex="10"
              >
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  m={0}
                >
                  <FormattedMessage
                    id="forEveryone.section7.heading1"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
              </Div>
            </Tween>
          </Div>

          <Div
            className="trigger15"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['unset', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Tween
              from={{
                autoAlpha: 0,
                y: 100,
                scale: 0.8,
              }}
              to={{
                scrollTrigger: {
                  trigger: '.trigger15',
                  start: 'top 50%',
                },
                autoAlpha: 1,
                scale: 1,
                y: 0,
              }}
              duration={0.75}
              stagger={0.25}
            >
              <Div
                maxWidth={['288px', '288px', '288px', '493px']}
                gridArea="1 / 1"
                alignSelf={['start', 'start', 'center']}
                mr={['initial', 'initial', 'auto']}
                ml={['auto', 'auto', 'auto']}
                zIndex="5"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493 664" className="svg-thre">
                  <title>3</title>
                  <path
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    d="M243.287 663.063c146.338 0 249.61-75.586 249.61-189.405 0-87.89-61.084-145.02-155.567-152.93v-2.636c77.783-14.063 130.957-70.313 130.957-148.096C468.287 68.482 378.2.806 246.363.806c-137.549 0-228.955 76.465-233.789 193.8h122.168c3.516-55.372 44.385-91.846 105.908-91.846 65.918 0 102.393 33.838 102.393 85.254 0 52.295-40.87 88.33-101.514 88.33h-76.904v95.8h78.223c71.63 0 115.136 35.157 115.136 93.165 0 53.613-46.582 90.966-112.94 90.966-69.872 0-113.817-35.156-117.773-88.77H.27C6.422 584.4 100.025 663.064 243.287 663.064z"
                  />
                </svg>
              </Div>
              <Div
                maxWidth="880px"
                textAlign={['left', 'left', 'center']}
                gridArea="1 / 1"
                zIndex="10"
              >
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  m={0}
                >
                  <FormattedMessage
                    id="forEveryone.section8.heading1"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  m={0}
                >
                  <FormattedMessage
                    id="forEveryone.section8.heading2"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
              </Div>
            </Tween>
          </Div>

          <Div
            className="trigger16"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['unset', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Tween
              from={{
                autoAlpha: 0,
                y: 100,
                scale: 0.8,
              }}
              to={{
                scrollTrigger: {
                  trigger: '.trigger16',
                  start: 'top 50%',
                },
                autoAlpha: 1,
                scale: 1,
                y: 0,
              }}
              duration={0.75}
              stagger={0.25}
            >
              <Div
                maxWidth={['288px', '288px', '288px', '525px']}
                gridArea="1 / 1"
                alignSelf={['start', 'start', 'center']}
                mr={['initial', 'initial', 'auto']}
                ml={['auto', 'auto', 'auto']}
                zIndex="5"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525 635" className="svg-four">
                  <title>4</title>
                  <path
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    d="M441.714 635V522.06h82.617V415.714h-82.617V.869H254.507C124.429 192.471 55.435 303.652.063 409.121v112.94h315.528V635h126.123zM318.228 419.668h-201.71v-3.516c51.417-99.316 106.788-185.888 199.073-321.24h2.637v324.756z"
                  />
                </svg>
              </Div>
              <Div
                maxWidth="880px"
                textAlign={['left', 'left', 'center']}
                gridArea="1 / 1"
                zIndex="10"
              >
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  m={0}
                >
                  <FormattedMessage
                    id="forEveryone.section9.heading1"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
              </Div>
            </Tween>
          </Div>

          <Div
            className="trigger17"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['unset', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Tween
              from={{
                autoAlpha: 0,
                y: 100,
                scale: 0.8,
              }}
              to={{
                scrollTrigger: {
                  trigger: '.trigger17',
                  start: 'top 50%',
                },
                autoAlpha: 1,
                scale: 1,
                y: 0,
              }}
              duration={0.5}
              stagger={0.25}
            >
              <Div
                maxWidth={['288px', '288px', '288px', '484px']}
                gridArea="1 / 1"
                alignSelf={['start', 'start', 'center']}
                mr={['initial', 'initial', 'auto']}
                ml={['auto', 'auto', 'auto']}
                zIndex="5"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484 650" className="svg-five">
                  <title>5</title>
                  <path
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    d="M238.47 649.063c145.46 0 244.776-91.407 244.776-225.88 0-123.486-87.012-212.695-208.3-212.695-64.6 0-115.137 26.807-141.065 69.434h-2.637l14.502-173.584h299.268V.869H41.596L10.395 365.615h118.652c21.973-36.474 63.281-58.447 111.182-58.447 68.554 0 116.455 49.219 116.455 119.531 0 68.994-47.9 117.334-117.334 117.334-61.524 0-108.985-36.914-116.455-90.527H.287C4.242 568.203 102.24 649.063 238.471 649.063z"
                  />
                </svg>
              </Div>
              <Div
                maxWidth="880px"
                textAlign={['left', 'left', 'center']}
                gridArea="1 / 1"
                zIndex="10"
              >
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  mb={['3rem', '3rem', 0, 0]}
                >
                  <FormattedMessage
                    id="forEveryone.section10.heading1"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
                <H3
                  fontSize={['2rem', '3.2rem', '4rem']}
                  lineHeight={['2.4rem', '3.4rem', '4.8rem']}
                  m={0}
                >
                  <FormattedMessage
                    id="forEveryone.section10.heading2"
                    values={{
                      br: <br />,
                    }}
                  />
                </H3>
              </Div>
            </Tween>
          </Div>
        </Section>
        <Section minHeight="120px" data-section={2}>
          <Div
            // className="trigger-about-text"
            display="flex"
            alignItems="center"
            maxWidth="1440px"
            minHeight={['30vh', '30vh', '120px', '200px', '200px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <NavLink
              to="/for-brands"
              display="inline-flex"
              fontSize={['2.3rem', '2.3rem', '3rem']}
              textDecoration="none"
              p="1rem"
              color="inherit"
            >
              <svg
                width="30px"
                height="31px"
                style={{ minWidth: '30px' }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(-125 -582)" fill="#000" fillRule="evenodd">
                  <g fillRule="nonzero">
                    <path d="M144.291 597.631l-4.729 4.296a.573.573 0 000 .883c.139.126.348.19.486.19a.794.794 0 00.487-.19l5.91-5.369a.573.573 0 000-.883l-5.91-5.37a.72.72 0 00-.973 0 .573.573 0 000 .884l4.73 4.296h-13.597c-.418 0-.695.251-.695.631 0 .378.277.632.695.632h13.596z" />
                    <path d="M139.448 582.25c8.45 0 15.302 6.827 15.302 15.25s-6.851 15.25-15.302 15.25a15.305 15.305 0 01-14.14-9.413.75.75 0 011.384-.576 13.805 13.805 0 0012.756 8.489c7.623 0 13.802-6.157 13.802-13.75s-6.179-13.75-13.802-13.75a13.806 13.806 0 00-12.569 8.06.75.75 0 11-1.364-.623 15.305 15.305 0 0113.933-8.937z" />
                  </g>
                  <circle cx="126" cy="597" r="1" />
                </g>
              </svg>
              <Span ml="2rem">{Intl.formatMessage({ id: 'continue.toBrands' })}</Span>
            </NavLink>
          </Div>
          <Footer />
        </Section>
      </main>
    </Layout>
  );
};

// export const query = graphql`
//   query getData {
//     yann: file(relativePath: { eq: "co/yann.jpg" }) {
//       childImageSharp {
//         fixed(width: 450, height: 450) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     anatol: file(relativePath: { eq: "co/anatol.jpg" }) {
//       childImageSharp {
//         fixed(width: 450, height: 450) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     deniz: file(relativePath: { eq: "co/deniz.jpg" }) {
//       childImageSharp {
//         fixed(width: 450, height: 450) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     felix: file(relativePath: { eq: "co/felix.jpg" }) {
//       childImageSharp {
//         fixed(width: 450, height: 450) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `;

export default ForReaders;
